import { Buffer } from 'buffer'
import { AbstractConnection, ConnectionStatus } from './index'
import type { IConnection, IConnectionConfig, IDeviceEvent } from './index'

export enum ScannerEventType {
  CONFIG,
  CONNECTED,
  DISCONNECTED,
  MESSAGE
}

interface ScannerEvent extends IDeviceEvent<ScannerEventType> {
  type: ScannerEventType
  data: { code: string } | null
}

export interface IScannerConnection extends IConnection {}

class ScannerResponse {
  dataBuffer: number[] = []
}

class ScannerConnection extends AbstractConnection<ScannerEventType, ScannerEvent> implements IScannerConnection {
  private serialPort: SerialPort | undefined

  private reader: ReadableStreamDefaultReader<Uint8Array> | undefined
  private writer: WritableStreamDefaultWriter<Uint8Array> | undefined

  private decoder = new TextDecoder()

  private databuffer: number[] = []

  private usbDataCallback(response: ScannerResponse) {
    const code = Buffer.from(response.dataBuffer).toString()
    this.eventListener({ type: ScannerEventType.MESSAGE, data: { code } }, this)
  }

  async open(): Promise<void> {
    this.eventListener({ type: ScannerEventType.CONFIG, data: null }, this)

    if (!this.serialOptions || !this.serialPortRequestOptions || !this.serialPortRequestOptions.filters?.length) {
      throw '找不到配置'
    }

    const usbProductIds = this.serialPortRequestOptions?.filters?.map((item) => item.usbProductId)
    const usbVendorIds = this.serialPortRequestOptions?.filters?.map((item) => item.usbVendorId)

    let p = (await navigator.serial.getPorts()).find((item) => {
      const info = item.getInfo()
      return usbProductIds?.includes(info.usbProductId) && usbVendorIds?.includes(info.usbVendorId)
    })

    if (!p) {
      console.log(this.serialPortRequestOptions)
      p = await navigator.serial.requestPort({ filters: [{ usbProductId: 0x090a, usbVendorId: 0x0c2e }] })
    }

    this.serialPort = p

    if (!this.serialPort) {
      throw '找不到设备'
    }

    this.status = ConnectionStatus.CONNECTED

    await this.serialPort.open(this.serialOptions)

    this.eventListener({ type: ScannerEventType.CONNECTED, data: null }, this)

    navigator.serial.addEventListener('disconnect', (e) => this.close())

    this.reader = this.serialPort.readable?.getReader()!

    while (this.serialPort.readable) {
      try {
        while (true) {
          const { value, done } = await this.reader.read()
          if (value?.length && value.length > 0) {
            this.databuffer.push(...value)

            this.usbDataCallback({ dataBuffer: this.databuffer })

            this.databuffer = []
          }

          if (done) {
            break
          }
        }
      } catch (error) {
      } finally {
        this.reader.releaseLock()
      }
    }
  }

  async close(): Promise<void> {
    try {
      // await this.reader?.cancel()

      // await this.serialPort?.close()

      this.status = ConnectionStatus.DISCONNECTED
      this.serialOptions = undefined
      this.serialPortRequestOptions = undefined

      // this.serialPort = undefined

      this.eventListener({ type: ScannerEventType.DISCONNECTED, data: null }, this)
    } catch (error) {}
  }
}

export const getScannerConnection = (eventListener: (event: ScannerEvent, instance: IScannerConnection) => any): IScannerConnection => {
  return new ScannerConnection(eventListener)
}
